const state = {
    ChangeList: [
        {
            id: 1,
            build: '0.0.1-beta1',
            description: "asd1 asd2",
            date: '2022-04-01'
        }
    ],
  };
  
  const namespaced = {
    namespaced: true,
  };
  
  const mutations = {
    LOAD_CHANGE_DATA(state, data) {
      state.ChangeList = data
    }
  };
  
  const actions = {
    LoadChangeData({ commit }) {
      this._vm.axios.get(document.location.protocol + '//scum-global.com/core/handler.php', {params: {request: "load_change_list"}}).then((response) => {
        if (response.data) {
          commit("LOAD_CHANGE_DATA", response.data);
        }
      })
    },
  };
  
  export default {
    namespaced,
    state,
    mutations,
    actions,
  };
  