import Vue from 'vue'
import VueRouter from 'vue-router'
//import MainPage from '../views/Main.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'MainPage',
    component: () => import('../views/Main.vue')
  },
  {
    path: '/bans',
    name: 'BansPage',
    component: () => import('../views/Bans.vue')
  },
  {
    path: '/bugs',
    name: 'BugsPage',
    component: () => import('../views/Bugs.vue')
  },
  {
    path: '/badsource',
    name: 'CheatSourcePage',
    component: () => import('../views/CheatSource.vue')
  },
  {
    path: '/betatestpagenotshow', // /eulaviolation
    name: 'EulaViolationPage',
    component: () => import('../views/EulaViolation.vue')
  },
  {
    path: '/changelog',
    name: 'ChangelogPage',
    component: () => import('../views/Changelog.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
