import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueKinesis from 'vue-kinesis'
import 'dtoaster/dist/dtoaster.css'
import DToaster from 'dtoaster'
import ToasterPresets from './json/toast_presets.json' //Your predefined toasts presets (optionally)
import mdiVue from 'mdi-vue/v2'
import * as mdijs from '@mdi/js'
import VueI18n from 'vue-i18n'
import i18n from './i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueYandexMetrika from '@bchteam/vue-yandex-metrika'

Vue.use(VueAxios, axios)

Vue.use(VueI18n)

Vue.use(mdiVue, {
  icons: mdijs
}) 

Vue.use(DToaster, {
  presets: ToasterPresets,
  position: 'top-right', //toasts container position on the screen
  containerOffset: '45px', //toasts container offset from top/bottom of the screen
})

Vue.use(VueKinesis)

Vue.config.productionTip = false
Vue.prototype.$appVersion = 'v0.0.3-beta'
Vue.prototype.$isWorks = false

Vue.use(VueYandexMetrika, {
  id: 88691018,
  router: router,
  env: process.env.NODE_ENV,
  scriptSrc: 'https://mc.yandex.ru/metrika/tag.js'
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')