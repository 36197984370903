const state = {
    UserInfo: {
        id: null,
        name: null,
        avatar: null,
        server: null,
        discord: null,
        dev: 0
    },
  };
  
  const namespaced = {
    namespaced: true,
  };
  
  const mutations = {
    LOAD_USER_INFO(state) {
      this._vm.axios.get(document.location.protocol + '//scum-global.com/core/handler.php', {params: {request: "check_user_access"}}).then((response) => {
        console.log(response.data)
        if (response.data) {
          if (!response.data.error || !response.data.isreg) {
            this._vm.axios.get(document.location.protocol + '//scum-global.com/core/handler.php', {params: {request: "get_user_data"}}).then((response) => {
              if (response.data) {
                if (!response.data.error) {
                  Object.assign(state.UserInfo, response.data)
                }
              }
            })
            .catch(function(e) {
              console.log(e);
            });
          }

          if (response.data.isreg) {
            location.reload();
          }
				}
      })
      .catch(function(e) {
        console.log(e);
      });
    }
  };
  
  const actions = {
    LoadUserInfo({ commit }) {
      commit("LOAD_USER_INFO");
    },
  };
  
  export default {
    namespaced,
    state,
    mutations,
    actions,
  };
  