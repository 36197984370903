<template>
  <header class="navbar navbar-expand-md navbar-light d-print-none">
    <div class="container-xl">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbar-menu"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <h1
        class="
          navbar-brand navbar-brand-autodark
          d-none-navbar-horizontal
          pe-0 pe-md-3
        "
      >
        <a href=".">
          <img
            src="@/assets/static/logo.png"
            width="150"
            height="62"
            alt="Global"
          />
        </a>
      </h1>
      <div class="navbar-nav flex-row order-md-last">
        <div class="nav-item d-none d-md-flex me-3">
          <div class="btn-list">
            <a
              v-if="UserInfo.id == null"
              href="#"
              class="btn"
              @click="getLoginUrlSteam()"
              rel="noreferrer"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-steam" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M16.5 5a4.5 4.5 0 1 1 -.653 8.953l-4.347 3.009l0 .038a3 3 0 0 1 -2.824 2.995l-.176 .005a3 3 0 0 1 -2.94 -2.402l-2.56 -1.098v-3.5l3.51 1.755a2.989 2.989 0 0 1 2.834 -.635l2.727 -3.818a4.5 4.5 0 0 1 4.429 -5.302z"></path>
                <circle fill="currentColor" cx="16.5" cy="9.5" r="1"></circle>
              </svg>
              {{ $t("headerPanel.authButton") }}
            </a>
            <a data-bs-toggle="modal" data-bs-target="#modal-link-server" v-if="UserInfo.id != null && UserInfo.server == null" href="#" class="btn" rel="noreferrer">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-link" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5"></path>
                    <path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5"></path>
                  </svg>
                  {{ $t('headerPanel.linkServerButton') }}
                </a>
                <a href="#" @click="changeLanguage()" class="btn" rel="noreferrer">
                  <img :src="require(`@/assets/static/flags/${$root.$i18n.locale}.png`)" width="32" height="22"/>
                </a>
                <a data-bs-toggle="modal" data-bs-target="#modal-link-server" v-if="UserInfo.id != null && UserInfo.server != null" href="#" class="btn" rel="noreferrer">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-server" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <rect x="3" y="4" width="18" height="8" rx="3"></rect>
                    <rect x="3" y="12" width="18" height="8" rx="3"></rect>
                    <line x1="7" y1="8" x2="7" y2="8.01"></line>
                    <line x1="7" y1="16" x2="7" y2="16.01"></line>
                  </svg>
                  {{ UserInfo.server.name }}
                </a>
          </div>
        </div>
        <div v-if="UserInfo.id != null" class="nav-item dropdown">
          <a
            href="#"
            class="nav-link d-flex lh-1 text-reset p-0"
            data-bs-toggle="dropdown"
            aria-label="Open user menu"
          >
            <span
              class="avatar avatar-sm"
              v-bind:style="{ backgroundImage: 'url(' + UserInfo.avatar + ')' }"
            ></span>
            <div class="d-none d-xl-block ps-2">
              <div>{{ UserInfo.name }}</div>
              <div v-if="UserInfo.dev == 1" class="mt-1 small text-muted">{{ $t("headerMenu.userStatus.developer") }}</div>
              <div v-if="UserInfo.server" class="mt-1 small text-muted">{{ $t("headerMenu.userStatus.serverOwner") }}</div>
              <div v-if="!UserInfo.server && UserInfo.dev == 0" class="mt-1 small text-muted">{{ $t("headerMenu.userStatus.user") }}</div>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
            <a href="#" v-on:click="logout()" class="dropdown-item">{{ $t('headerPanel.userActions.logout') }}</a>
          </div>
        </div>
      </div>
    </div>

    <!-- link server modal -->
    <div class="modal modal-blur fade" id="modal-link-server" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="UserInfo && UserInfo.server && UserInfo.server.status <= 1" class="modal-title">{{ $t('headerPanel.linkServer.title_start') }}</h5>
            <h5 v-if="UserInfo && UserInfo.server && UserInfo.server.status == 2" class="modal-title">{{ $t('headerPanel.linkServer.title_final') }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            
            <div class="col-md-6 col-lg-12">

              <div class="card" v-if="UserInfo && !UserInfo.server">
                <div class="card-stamp">
                  <div class="card-stamp-icon bg-blue">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-number-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M13 20v-16l-5 5"></path>
                    </svg>
                  </div>
                </div>
                <div class="card-body">
                  <h3 class="card-title">{{ $t('headerPanel.linkServer.title_steps_start') }}</h3>
                  <p class="text-muted">{{ $t('headerPanel.linkServer.step1_text') }}</p>
                  <div class="input-group mb-2">
                    <input v-model="linkServer.bid" type="text" class="form-control" placeholder="ID:">
                    <span v-if="restQueryStatus" class="input-icon-addon">
                      <div class="spinner-border spinner-border-sm text-muted" role="status"></div>
                    </span>
                    <button v-if="!restQueryStatus" @click="checkbattlemetricsServer()" class="btn" type="button">{{ $t('headerPanel.linkServer.stepsButtonCheck') }}</button>
                  </div>
                </div>
              </div>

              <div class="card" v-if="UserInfo && UserInfo.server && UserInfo.server.status == 0">
                <div class="card-stamp">
                  <div class="card-stamp-icon bg-blue">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-number-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M8 8a4 4 0 1 1 8 0c0 1.098 -.564 2.025 -1.159 2.815l-6.841 9.185h8"></path>
                    </svg>
                  </div>
                </div>
                <div class="card-body">
                  <h3 class="card-title">{{ $t('headerPanel.linkServer.title_steps_start') }}</h3>
                  <p class="text-muted">{{ $t('headerPanel.linkServer.step2_text') }}</p>
                  <div class="input-group mb-2">
                    <input v-if="UserInfo.server" disabled v-model="UserInfo.server.token" type="text" class="form-control">
                    <span v-if="restQueryStatus" class="input-icon-addon">
                      <div class="spinner-border spinner-border-sm text-muted" role="status"></div>
                    </span>
                    <button v-if="!restQueryStatus" @click="checkToken()" class="btn" type="button">{{ $t('headerPanel.linkServer.stepsButtonCheck') }}</button>
                  </div>
                  <p>{{ $t('headerPanel.linkServer.step2_desc') }}</p>
                </div>
              </div>

              <div class="card" v-if="UserInfo && UserInfo.server && UserInfo.server.name == 'None' && UserInfo.server.status == 1">
                <div class="card-stamp">
                  <div class="card-stamp-icon bg-blue">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-number-3" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M12 12a4 4 0 1 0 -4 -4"></path>
                      <path d="M8 16a4 4 0 1 0 4 -4"></path>
                    </svg>
                  </div>
                </div>
                <div class="card-body">
                  <h3 class="card-title">{{ $t('headerPanel.linkServer.title_final') }}</h3>
                  <p class="text-muted">{{ $t('headerPanel.linkServer.step3_text') }}</p>
                  <div class="input-group mb-2">
                    <input v-model="linkServer.name" type="text" class="form-control" placeholder="Name...">
                    <span v-if="restQueryStatus" class="input-icon-addon">
                      <div class="spinner-border spinner-border-sm text-muted" role="status"></div>
                    </span>
                    <button v-if="!restQueryStatus" @click="updateServerName()" class="btn" type="button">{{ $t('headerPanel.linkServer.step3Button') }}</button>
                  </div>
                </div>
              </div>

              <div class="card" v-if="UserInfo && UserInfo.server && UserInfo.server.serverName != 'None' && UserInfo.server.status == 2 && !UserInfo.discord">
                <div class="card-stamp">
                  <div class="card-stamp-icon bg-blue">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-number-3" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M12 12a4 4 0 1 0 -4 -4"></path>
                      <path d="M8 16a4 4 0 1 0 4 -4"></path>
                    </svg>
                  </div>
                </div>
                <div class="card-body">
                  <h3 class="card-title">{{ $t('headerPanel.linkServer.title_final') }}</h3>
                  <p class="text-muted">{{ $t('headerPanel.linkServer.step4_text') }}</p>
                  <div class="input-group mb-2">
                    <input v-model="linkServer.discord" type="text" class="form-control" placeholder="Discord url...">
                    <span v-if="restQueryStatus" class="input-icon-addon">
                      <div class="spinner-border spinner-border-sm text-muted" role="status"></div>
                    </span>
                    <button v-if="!restQueryStatus" @click="updateDiscord()" class="btn" type="button">{{ $t('headerPanel.linkServer.step3Button') }}</button>
                  </div>
                </div>
              </div>

            </div>

            <div v-if="UserInfo && UserInfo.server && UserInfo.server.status == 2 && UserInfo.discord && UserInfo.server.serverName != 'None'">
              <div class="alert alert-success" role="alert">
                Coming soon... close modal
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal">{{ $t('bugsPage.listBugs.modalDescription.hideButton') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!-- -->

  </header>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "HeaderComponent",
  data: function () {
    return {
      restQueryStatus: false,
      currentStep: null,
      linkServer: {
        bid: null,
        name: null,
        discord: null
      }
    }
  },
  methods: {
    ...mapActions("userInfo", ["LoadUserInfo"]),
    getLoginUrlSteam() {
      let _self = this
      this.axios.get(document.location.protocol + '//scum-global.com/core/handler.php', {params: {request: "get_login_url"}}).then((response) => {
        if (response.data) {
					document.location.href = response.data.url;
				}
      })
      .catch(function(e) {
        console.log(e);
        _self.restQueryStatus = false
        _self.$dtoast.pop({
          preset: 'error',
          heading: _self.$t('headerPanel.linkServer.notice.auth.header'),
          content:  _self.$t('headerPanel.linkServer.notice.auth.text'),
        })
      });
    },
    changeLanguage() {
      if (this.$root.$i18n.locale == 'ru') {
        this.$root.$i18n.locale = 'en'
      } else {
        this.$root.$i18n.locale = 'ru'
      }
    },
    updateServerName() {
      let _self = this
      this.axios.get(document.location.protocol + '//scum-global.com/core/handler.php', {params: {request: "update_server_name", serverName: this.linkServer.name}}).then((response) => {
        if (response.data.success) {
          _self.changeStep(4)
        }
      })
      this.changeStep(4)
      this.$dtoast.pop({
        preset: 'success',
        heading: this.$t('headerPanel.linkServer.notice.updateServerName.header'),
        content:  this.$t('headerPanel.linkServer.notice.updateServerName.text'),
      })
    },
    updateDiscord() {
      let _self = this
      this.axios.get(document.location.protocol + '//scum-global.com/core/handler.php', {params: {request: "send_discord", discord: this.linkServer.discord}}).then((response) => {
        if (response.data.success) {
          _self.changeStep(4)
        }
      })
      this.changeStep(4)
      this.$dtoast.pop({
        preset: 'success',
        heading: this.$t('headerPanel.linkServer.notice.updateServerName.header'),
        content:  this.$t('headerPanel.linkServer.notice.updateServerName.text'),
      })
    },
    logout() {
      this.axios.get(document.location.protocol + '//scum-global.com/core/handler.php', {params: {request: "logout"}}).then((response) => {
        if (response.data.success) {
          location.reload();
        }
      })
    },
    checkToken() {
      this.restQueryStatus = true
      let _self = this
      this.axios.get('https://api.battlemetrics.com/servers/' + this.UserInfo.server.battleID).then((response) => {
        let request = response.data.data;
        if (request.attributes.name.indexOf(_self.UserInfo.server.token) != -1) {
          _self.axios.get(document.location.protocol + '//scum-global.com/core/handler.php', {params: {request: "accept_server"}}).then((response) => {
            if (response.data.success) {
              _self.changeStep(3)
            }
          })
        } else {
          this.restQueryStatus = false
          _self.$dtoast.pop({
            preset: 'warning',
            heading: _self.$t('headerPanel.linkServer.notice.checkToken.header'),
            content:  _self.$t('headerPanel.linkServer.notice.checkToken.text'),
          })
        }
      })
    },
    getNumberOfDays(start, end) { 
      const date1 = new Date(start); 
      const date2 = new Date(end); 
  
      const oneDay = 1000 * 60 * 60 * 24; 
      const diffInTime = date2.getTime() - date1.getTime(); 
      const diffInDays = Math.round(diffInTime / oneDay); 
      
      return diffInDays; 
    },
    checkbattlemetricsServer() {
      this.restQueryStatus = true
      let _self = this
      this.axios.get('https://api.battlemetrics.com/servers/' + this.linkServer.bid).then((response) => {
        let request = response.data.data;
        if (request.relationships.game.data.id == 'scum') {
          let today = new Date();
          let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
          if (_self.getNumberOfDays(date, request.attributes.createdAt.split('T')[0]) < -32) {
            _self.axios.get(document.location.protocol + '//scum-global.com/core/handler.php', {params: {request: "send_battlemetrics_id", battleID: _self.linkServer.bid}}).then((response) => {
              if (response.data.success) {
                _self.changeStep(2);
              }
            })
          } else {
            _self.restQueryStatus = false
            _self.$dtoast.pop({
              preset: 'error',
              heading: _self.$t('headerPanel.linkServer.notice.serverDown30Days.header'),
              content:  _self.$t('headerPanel.linkServer.notice.serverDown30Days.text'),
            })
          }
        }
      })
      .catch(function(e) {
        console.log(e);
        _self.restQueryStatus = false
        _self.$dtoast.pop({
          preset: 'error',
          heading: _self.$t('headerPanel.linkServer.notice.apiBattleMetrics.header'),
          content:  _self.$t('headerPanel.linkServer.notice.apiBattleMetrics.text'),
        })
      });
    },
    changeStep(step) {
      this.LoadUserInfo()
      this.restQueryStatus = false
      this.currentStep = step
    }
  },
  computed: {
    ...mapState("userInfo", ["UserInfo"]),
  },
  created() {
    console.log('Client protocol: ' + document.location.protocol)
    console.log('Client language: ' + navigator.language)
    if (navigator.language == 'ru-RU') {
      this.$root.$i18n.locale = 'ru'
    } else {
      this.$root.$i18n.locale = 'en'
    }
    this.LoadUserInfo()
    console.log(this.currentStep)
  }
};
</script>