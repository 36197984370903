<template>
  <div id="app">
    <div class="page">
      <header-component></header-component>
      <navbar-component></navbar-component>
      <div class="page-wrapper">
        <div class="container-xl"></div>
        <div class="page-body">
          <router-view/>
        </div>
        <footer-component></footer-component>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import NavbarComponent from "@/components/NavbarComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "App",
  components: {
    HeaderComponent,
    NavbarComponent,
    FooterComponent
  },
};
</script>