const state = {
    searchedBans: [],
    BansInfo: [],
  };
  
  const namespaced = {
    namespaced: true,
  };

  const paginated = (items, n) => new Array(Math.ceil(items.length / n))
    .fill()
    .map(() => items.splice(0, n));

  const searchFunc = (state, author, search) => search == null ?
    author == null?
        state.BansInfo.filter(v=>v.active == 1)
        :
        state.BansInfo.filter( v => v.steamid === author)
    :
    author == null?
        state.BansInfo.filter(
            v =>
                v.active == 1 &&
                (v.steamid.includes(search) ||
                    v.playerName.includes(search))):
        state.videos.filter(
            v =>
                v.author === parseInt(author) &&
                v.steamid.includes(search) ||
                v.playerName.includes(search));

  const getters = {
    paginatedBans: (state) => (steamid, search, count) => paginated(searchFunc(state,steamid, search), count),
    allBans: (state) => (steamid, search) => searchFunc(state, steamid, search),
    getCountBans: (state) => () => state.BansInfo.length,
    getBanById: (state) => (id) => state.BansInfo.find(v =>
        v.active === 1 &&
        v.id === parseInt(id) ),
    allBansByTag:(state) => (steamid) => state.BansInfo.find(v =>
        v.active === 1 &&
        v.steamid.includes(steamid))
  };
  
  const mutations = {
    LOAD_BANS_INFO(state, data) {
      state.BansInfo = data;
    }
  };
  
  const actions = {
    LoadBansInfo({ commit }) {
      return new Promise((resolve, reject) => {
        this._vm.axios.get(document.location.protocol + '//scum-global.com/core/handler.php', {params: {request: "load_bans_list"}}).then((response) => {
          if (response.data) {
            commit("LOAD_BANS_INFO", response.data);
            resolve('good');
          } else {
            reject('fall');
          }
        })
      })
    },
  };
  
  export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
  };
  