<template>
  <div class="navbar-expand-md">
    <div class="collapse navbar-collapse" id="navbar-menu">
      <div class="navbar navbar-light">
        <div class="container-xl">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link to="/">
              <a class="nav-link" href="#">
                <span class="nav-link-icon d-md-none d-lg-inline-block"
                  ><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <polyline points="5 12 3 12 12 3 21 12 19 12" />
                    <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                    <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                  </svg>
                </span>
                <span class="nav-link-title"> {{ $t("headerMenu.home") }} </span>
              </a>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/bans">
              <a class="nav-link" href="#">
                <span class="nav-link-icon d-md-none d-lg-inline-block"
                  ><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-lock-access" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M4 8v-2a2 2 0 0 1 2 -2h2"></path>
                    <path d="M4 16v2a2 2 0 0 0 2 2h2"></path>
                    <path d="M16 4h2a2 2 0 0 1 2 2v2"></path>
                    <path d="M16 20h2a2 2 0 0 0 2 -2v-2"></path>
                    <rect x="8" y="11" width="8" height="5" rx="1"></rect>
                    <path d="M10 11v-2a2 2 0 1 1 4 0v2"></path>
                  </svg>
                </span>
                <span class="nav-link-title"> {{ $t("headerMenu.bans") }} </span>
              </a>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/bugs">
              <a class="nav-link" href="#">
                <span class="nav-link-icon d-md-none d-lg-inline-block"
                  ><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-bug" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M9 9v-1a3 3 0 0 1 6 0v1"></path>
                    <path d="M8 9h8a6 6 0 0 1 1 3v3a5 5 0 0 1 -10 0v-3a6 6 0 0 1 1 -3"></path>
                    <line x1="3" y1="13" x2="7" y2="13"></line>
                    <line x1="17" y1="13" x2="21" y2="13"></line>
                    <line x1="12" y1="20" x2="12" y2="14"></line>
                    <line x1="4" y1="19" x2="7.35" y2="17"></line>
                    <line x1="20" y1="19" x2="16.65" y2="17"></line>
                    <line x1="4" y1="7" x2="7.75" y2="9.4"></line>
                    <line x1="20" y1="7" x2="16.25" y2="9.4"></line>
                  </svg>
                </span>
                <span class="nav-link-title"> {{ $t("headerMenu.bugs") }} </span>
              </a>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/badsource">
              <a class="nav-link" href="#">
                <span class="nav-link-icon d-md-none d-lg-inline-block"
                  ><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash-off" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <line x1="3" y1="3" x2="21" y2="21"></line>
                    <path d="M4 7h3m4 0h9"></path>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="14" x2="14" y2="17"></line>
                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l.077 -.923"></path>
                    <line x1="18.384" y1="14.373" x2="19" y2="7"></line>
                    <path d="M9 5v-1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                  </svg>
                </span>
                <span class="nav-link-title"> {{ $t("headerMenu.infections") }} </span>
              </a>
              </router-link>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://trader.scum-global.com/" target="_blank">
                <span class="nav-link-icon d-md-none d-lg-inline-block"
                  ><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-settings-automation" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z"></path>
                    <path d="M10 9v6l5 -3z"></path>
                  </svg>
                </span>
                <span class="nav-link-title"> {{ $t("headerMenu.traderConfig") }} </span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://monitoring.scum-global.com/" target="_blank">
                <span class="nav-link-icon d-md-none d-lg-inline-block"
                  ><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-device-desktop-analytics"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 4m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1z" /><path d="M7 20h10" /><path d="M9 16v4" /><path d="M15 16v4" /><path d="M9 12v-4" /><path d="M12 12v-1" /><path d="M15 12v-2" /><path d="M12 12v-1" /></svg>
                </span>
                <span class="nav-link-title"> {{ $t("headerMenu.monitoring") }} </span>
              </a>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#navbar-layout"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                role="button"
                aria-expanded="false"
              >
                <span class="nav-link-icon d-md-none d-lg-inline-block"
                  ><!-- Download SVG icon from http://tabler-icons.io/i/layout-2 -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <rect x="4" y="4" width="6" height="5" rx="2" />
                    <rect x="4" y="13" width="6" height="7" rx="2" />
                    <rect x="14" y="4" width="6" height="7" rx="2" />
                    <rect x="14" y="15" width="6" height="5" rx="2" />
                  </svg>
                </span>
                <span class="nav-link-title"> {{ $t("headerMenu.scumDropdown.name") }} </span>
              </a>
              <div class="dropdown-menu">
                <div class="dropdown-menu-columns">
                  <div class="dropdown-menu-column">
                    <a class="dropdown-item" href="https://store.steampowered.com/app/513710/SCUM/" target="_blank">
                      {{ $t("headerMenu.scumDropdown.buyGame") }}
                    </a>
                    <a class="dropdown-item" href="https://discord.gg/scum" target="_blank">
                      {{ $t("headerMenu.scumDropdown.discordGame") }}
                    </a>
                    <a class="dropdown-item" href="https://scum-map.com/" target="_blank">
                      {{ $t("headerMenu.scumDropdown.mapGame") }}
                    </a>
                    <a class="dropdown-item" href="https://scum.fandom.com/wiki/Scum_Wiki" target="_blank">
                      {{ $t("headerMenu.scumDropdown.scumWiki") }}
                    </a>
                    <a class="dropdown-item" href="https://scumadminhelper.com/" target="_blank">
                      {{ $t("headerMenu.scumDropdown.adminHelper") }}
                    </a>
                    <!--<a class="dropdown-item" href="https://discord.gg/Hnjg2gr3MS" target="_blank">
                      {{ $t("headerMenu.scumDropdown.whalleyBot") }}
                    </a>-->
                  </div>
                </div>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#navbar-layout"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                role="button"
                aria-expanded="false"
              >
                <span class="nav-link-icon d-md-none d-lg-inline-block"
                  ><!-- Download SVG icon from http://tabler-icons.io/i/layout-2 -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <rect x="4" y="4" width="6" height="5" rx="2" />
                    <rect x="4" y="13" width="6" height="7" rx="2" />
                    <rect x="14" y="4" width="6" height="7" rx="2" />
                    <rect x="14" y="15" width="6" height="5" rx="2" />
                  </svg>
                </span>
                <span class="nav-link-title"> {{ $t("headerMenu.hostingsDropdown.name") }} </span>
              </a>
              <div class="dropdown-menu">
                <div class="dropdown-menu-columns">
                  <div class="dropdown-menu-column">
                    <a class="dropdown-item" href="https://www.gghost.games/store/scum" target="_blank">
                      {{ $t("headerMenu.hostingsDropdown.gghost") }}
                    </a>
                  </div>
                </div>
              </div>
            </li>
            <li class="nav-item">
              <router-link to="/changelog">
              <a class="nav-link" href="#">
                <span class="nav-link-icon d-md-none d-lg-inline-block"
                  ><!-- Download SVG icon from http://tabler-icons.io/i/file-plus -->
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-exchange" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <circle cx="5" cy="18" r="2"></circle>
                    <circle cx="19" cy="6" r="2"></circle>
                    <path d="M19 8v5a5 5 0 0 1 -5 5h-3l3 -3m0 6l-3 -3"></path>
                    <path d="M5 16v-5a5 5 0 0 1 5 -5h3l-3 -3m0 6l3 -3"></path>
                  </svg>
                </span>
                <span class="nav-link-title"> {{ $t("headerMenu.changeList") }} </span>
              </a>
              </router-link>
            </li>
          </ul>
          <div
            class="
              my-2 my-md-0
              flex-grow-1 flex-md-grow-0
              order-first order-md-last
            "
          >

          </div>
        </div>
      </div>
    </div>

    <div v-if="$isWorks" class="alert alert-important alert-warning alert-dismissible" role="alert">
      <div>
        <div>
          <h3 style="text-align: center">{{ $t('headerMenu.devWorks') }}</h3>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "NavbarComponent",
};
</script>