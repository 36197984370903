import Vue from "vue";
import Vuex from "vuex";

import userInfo from '@/store/modules/userInfo';
import bansInfo from '@/store/modules/bansInfo';
import bugsInfo from '@/store/modules/bugsInfo';
import changeList from '@/store/modules/changeLog';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    userInfo,
    bansInfo,
    bugsInfo,
    changeList
  },
});
