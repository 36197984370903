const state = {
    BugsInfo: [],
  };
  
  const namespaced = {
    namespaced: true,
  };
  
  const mutations = {
    LOAD_BUGS_DATA(state, data) {
      state.BugsInfo = data
    }
  };
  
  const actions = {
    LoadBugsData({ commit }) {
      return new Promise((resolve, reject) => {
        this._vm.axios.get(document.location.protocol + '//scum-global.com/core/handler.php', {params: {request: "load_bugs_list"}}).then((response) => {
          if (response.data) {
            commit("LOAD_BUGS_DATA", response.data);
            resolve('good');
          } else {
            reject('fall');
          }
        })
      })
    },
  };
  
  export default {
    namespaced,
    state,
    mutations,
    actions,
  };
  